import axios from "axios"
import React, { useCallback, useMemo } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  makeStyles,
  Typography,
  Container,
  Button,
  Box,
} from "@material-ui/core"
import { Form } from "react-final-form"
import { TextField, Checkboxes } from "mui-rff"
import { FORM_ERROR } from "final-form"
import { navigate } from "gatsby"

const useStyles = makeStyles(theme => ({
  TextField: {
    marginTop: 20,
  },
  contact: {
    display: "flex",
    justifyContent: "space-around",
    // alignItems: "stretch"
  },
  check: {
    marginTop: 20,
    marginBottom: 10,
  },
  space: {
    padding: theme.spacing(3, 3),
  },
}))

const EstimatePage = props => {
  const classes = useStyles(props)
  const websiteRequired = useCallback(value => {
    if (!value) return "Website is required"
  }, [])
  const emailRequired = useCallback(value => {
    if (!value) return "Email address is required"
  }, [])
  const submit = useCallback(
    async ({ website, email, phone, accessibilityAssessment, notes }) => {
      const estimateSubmission = {
        website_submitted: website,
        email,
        phone,
        accessibility: accessibilityAssessment,
        notes,
        date_tested: new Date(),
      }

      try {
        await axios.post(
          "https://pace-bdc13.firebaseio.com/estimates.json",
          estimateSubmission
        )

        navigate("/estimate/success/")
      } catch (err) {
        console.error(err)
        return {
          [FORM_ERROR]: "Unexpected error",
        }
      }
    },
    []
  )

  const initialValues = useMemo(() => {
    const query = new URLSearchParams(props.location.search)
    const website = query.get("website") || ""
    return {
      website,
    }
  }, [props.location])

  return (
    <Layout titleId="estimate-title">
      <SEO title="Get a free estimate" />
      <Container maxWidth="sm">
        <Box mb={2}>
          <Typography id="estimate-title" gutterBottom>
            Get a free initial estimate on what it will take to get your site
            loading more quickly to increase your traffic and conversions.
          </Typography>

          <Typography color="secondary" variant="overline" gutterBottom>
            You can expect an answer within 1 to 2 business days.
          </Typography>
          <div className={classes.space}></div>

          <Form
            initialValues={initialValues}
            onSubmit={submit}
            render={({ handleSubmit, submitting, error, submitError }) => (
              <form className={classes.form} onSubmit={handleSubmit} noValidate>
                <TextField
                  id="estimate-website"
                  name="website"
                  label="Website"
                  type="text"
                  fullWidth
                  required
                  fieldProps={{
                    validate: websiteRequired,
                  }}
                />
                <div className={classes.contact}>
                  <div style={{ flex: 1, marginRight: 10 }}>
                    <TextField
                      id="estimate-email"
                      name="email"
                      className={classes.TextField}
                      label="Email Address"
                      type="email"
                      fullWidth
                      variant="outlined"
                      required
                      fieldProps={{
                        validate: emailRequired,
                      }}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <TextField
                      id="estimate-phone"
                      name="phone"
                      className={classes.TextField}
                      label="Phone (optional)"
                      type="text"
                      fullWidth
                      variant="outlined"
                    />
                    {/* <NumberFormat format="+1 (###) ###-####" mask="_" /> */}
                  </div>
                </div>
                <div className={classes.check}>
                  <Checkboxes
                    name="accessibilityAssessment"
                    data={[
                      {
                        label:
                          "Include a free initial accessibility assessment?",
                      },
                    ]}
                  />
                </div>
                <TextField
                  className={classes.TextField}
                  id="estimate-notes"
                  name="notes"
                  label="Notes (optional)"
                  multiline
                  rows="4"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
                {(error || submitError) && (
                  <Typography component="p" paragraph color="error">
                    {error || submitError}
                  </Typography>
                )}
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                >
                  <Button type="submit" color="primary" disabled={submitting}>
                    Submit
                  </Button>
                </Box>
              </form>
            )}
          />
        </Box>
      </Container>
    </Layout>
  )
}

export default EstimatePage
